const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://img2w69sd0.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://mvmkdo89y4.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://w5bsza9z49.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.demo.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.demo.forwoodsafety.com',
    WEBSOCKET: 'wss://4ttf2gjoi3.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;